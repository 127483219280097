import { graphql, useStaticQuery } from 'gatsby';

export const useData = () => {
  const query = useStaticQuery(
    graphql`
      query SiteData {
        allModuleJson {
          nodes {
            id
            title
            description
            slug
            headerImage
            selectTitle
            type
            selectSubtitle
            longAnswerCategory
            groups {
              id
              title
              slug
              number
              tileImage
              subtitle
              explain
              headerImage
              matches {
                type
                matchId
                answer
              }
              answer
            }
          }
        }
        allMatchJson {
          nodes {
            id
            title
          }
        }
        allTutorialJson {
          nodes {
            steps {
              id
              icon
              description
              title
            }
            moduleId
          }
        }
      }
    `
  );

  return {
    modules: query.allModuleJson.nodes,
    tutorials: query.allTutorialJson.nodes,
    matches: query.allMatchJson.nodes
  };
};

export const useModule = (moduleSlug: string) => {
  const { modules } = useData();

  const foundModule = modules.find((m: ModuleProps) => m.slug === moduleSlug);

  return foundModule;
};

export const useSubmodule = (
  module: ModuleProps | undefined,
  submoduleSlug: string
) => {
  if (!module) {
    return undefined;
  }

  return module.groups.find((s: SubModuleProps) => s.slug === submoduleSlug);
};

export const useModuleSubmodule = (
  moduleSlug: string,
  submoduleSlug: string
) => {
  const module: ModuleProps | undefined = useModule(moduleSlug);
  const submodule: SubModuleProps | undefined = useSubmodule(
    module,
    submoduleSlug
  );

  return {
    module,
    submodule
  };
};

export const useMatches = () => {
  const { matches } = useData();

  return matches;
};

export const useTutorial = (module: ModuleProps | undefined) => {
  const { tutorials } = useData();

  if (!module) {
    return undefined;
  }

  return tutorials.find((t: TutorialProps) => t.moduleId === module.id);
};

export const useModuleTutorial = (moduleSlug: string) => {
  const module: ModuleProps | undefined = useModule(moduleSlug);
  const tutorial: TutorialProps | undefined = useTutorial(module);

  return {
    module,
    tutorial
  };
};

export const currentAnswer = (
  module: ModuleProps,
  submodule: SubModuleProps,
  answers: Array<AnswerProps>
) => answers.find(a => a.moduleId === module.id && a.id === submodule.id);

export const useMatchAnswers = (
  matches: any,
  module: ModuleProps,
  submodule: SubModuleProps,
  answers: Array<AnswerProps>
) => {
  const mappedAnswer = currentAnswer(module, submodule, answers);

  const matchAnswers =
    matches.map((m: any) => {
      const type = submodule.matches.find(sm => sm.matchId === m.id);
      let hasValue = false;

      if (mappedAnswer?.matches) {
        hasValue = mappedAnswer.matches.some((c: string) => {
          return c === m.id;
        });
      }

      return {
        ...m,
        visited: !!hasValue,
        value: m.id,
        matchType: type ? type.type : 'incorrect'
      };
    }) || false;

  return matchAnswers;
};

export const useCurrentLongAnswer = (
  module: ModuleProps,
  submodule: SubModuleProps,
  answers: Array<AnswerProps>
) => {
  const mappedAnswer = currentAnswer(module, submodule, answers);

  if (mappedAnswer?.answer) {
    return mappedAnswer.answer;
  }

  return '';
};
export const useCurrentMatch = (
  module: ModuleProps,
  submodule: SubModuleProps,
  answers: Array<AnswerProps>
) => {
  // Find the answer in the current answer stack
  const matches = useMatches();
  const answer =
    answers.find(a => a.id === submodule.id && module.id === a.moduleId) ||
    false;
  let goodMatch = false;
  let strongMatch = false;
  let currentMatch = false;

  if (answer) {
    const correctMatches = submodule.matches.filter(
      s => s.type === 'strong' || s.type === 'good'
    );

    answer.matches.forEach(match => {
      correctMatches.forEach((m: any) => {
        if (m.matchId === match && m.type === 'good') {
          goodMatch = m.matchId;
        }
        if (m.matchId === match && m.type === 'strong') {
          strongMatch = m.matchId;
        }
      });
    });

    // todo: you could do a strong match, then a good match. in theory. base off conversation with Josh.
    if (goodMatch) {
      currentMatch = goodMatch;
    }
    if (strongMatch) {
      currentMatch = strongMatch;
    }

    return matches.find((m: any) => m.id === currentMatch) || false;
  }

  return false;
};

export const useCurrentMatchAnswer = (
  module: ModuleProps,
  submodule: SubModuleProps,
  answers: Array<AnswerProps>
) => {
  if (module.type === 'explain') {
    return false;
  }
  const currentMatch = useCurrentMatch(module, submodule, answers); // eslint-disable-line react-hooks/rules-of-hooks
  let matchContent: MatchProps | boolean = false;

  if (currentMatch) {
    matchContent =
      submodule.matches.find(sm => sm.matchId === currentMatch.id) || false;
  }

  return matchContent;
};

export const matchSubmoduleComplete = (
  module: ModuleProps,
  submodule: SubModuleProps,
  answers: Array<AnswerProps>
) => {
  const answer =
    answers.find(a => a.id === submodule.id && module.id === a.moduleId) ||
    false;
  const goodEnoughMatch = submodule.matches.filter(
    s => s.type === 'strong' || s.type === 'good'
  );

  let longAnswer = false;
  let matches = false;

  if (answer) {
    const hasAnswered =
      answer.matches.find(m => goodEnoughMatch.find(gm => m === gm.matchId)) ||
      false;

    if (answer.answer) {
      longAnswer = true;
    }
    if (hasAnswered) {
      matches = true;
    }
  }

  return longAnswer && matches;
};
export const explainSubmoduleComplete = (
  module: ModuleProps,
  submodule: SubModuleProps,
  answers: Array<AnswerProps>
) => {
  const answer =
    answers.find(a => a.id === submodule.id && module.id === a.moduleId) ||
    false;

  return answer && answer.answer;
};

export const useSubmoduleComplete = (
  module: ModuleProps,
  submodule: SubModuleProps,
  answers: Array<AnswerProps>
) => {
  if (!submodule || answers.length === 0) {
    return false;
  }
  const moduleCompleteFunction: Function =
    module.type === 'matchAndExplain'
      ? matchSubmoduleComplete
      : explainSubmoduleComplete;

  return moduleCompleteFunction(module, submodule, answers);
};

export const useAllSubmodulesComplete = (
  module: ModuleProps | undefined,
  answers: Array<AnswerProps>
) => {
  if (!module || answers.length === 0) {
    return false;
  }
  let answeredCount = 0;
  const submoduleSize = module.groups.length;
  const moduleCompleteFunction =
    module.type === 'matchAndExplain'
      ? matchSubmoduleComplete
      : explainSubmoduleComplete;

  module.groups.forEach((s: SubModuleProps) => {
    if (moduleCompleteFunction(module, s, answers)) {
      answeredCount += 1;
    }
  });

  return answeredCount === submoduleSize;
};

export const useModuleComplete = (
  module: ModuleProps,
  completedModules: Array<string>
) => {
  return completedModules.some((c: string) => c === module.id);
};

export const useMappedAnswers = (
  answers: Array<AnswerProps>,
  moduleSlug: string
) => {
  const module: ModuleProps = useModule(moduleSlug);
  const moduleQuestions: Array<SubModuleProps> = module.groups;
  const matches = useMatches();
  const relevantAnswers = answers.filter(
    (a: AnswerProps) => a.moduleId === module.id
  );

  // sort the answers by ID so they render as they are ordered in the app
  relevantAnswers.sort((a, b) => {
    const nameA = a.id.toUpperCase();
    const nameB = b.id.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const explainQuestions = relevantAnswers.map((a: AnswerProps) => {
    const relevantQuestion = moduleQuestions.find((q: SubModuleProps) => {
      return q.id === a.id;
    });

    return {
      title: relevantQuestion?.title,
      subtitle: relevantQuestion?.subtitle,
      answer: a.answer,
      explainQuestion: relevantQuestion?.explain,
      suggestedAnswer: relevantQuestion?.answer
    };
  });

  const matchAndExplain = relevantAnswers.map((a: AnswerProps) => {
    const relevantQuestion = moduleQuestions.find((q: SubModuleProps) => {
      return q.id === a.id;
    });

    if (module.type === 'explain') {
      return null;
    }
    const selectedMatch = a.matches;
    const actualMatches = relevantQuestion?.matches;

    // Get the strong answer and the good answer out of the module group
    // const strongAnswer = actualMatches?.find(m => m.type === 'strong');

    // there isn't always a good answer.
    const goodAnswer = actualMatches?.find(m => m.type === 'good');
    const strongAnswer = actualMatches?.find(m => m.type === 'strong');

    let answeredStrong = false;
    // there is always a strong answer.
    let finalAnswer = strongAnswer;

    if (strongAnswer) {
      if (selectedMatch.some((m: string) => strongAnswer.matchId === m)) {
        answeredStrong = true;
      }
    }
    finalAnswer = answeredStrong === true ? strongAnswer : goodAnswer;
    const matchTitle = matches.find((m: any) => finalAnswer?.matchId === m.id);
    const strongestMatchTitle = matches.find(
      (m: any) => strongAnswer?.matchId === m.id
    );

    return {
      title: relevantQuestion?.title,
      selectedMatch: matchTitle.title,
      selectedAnswer: a.answer,
      strongestMatch: strongestMatchTitle.title,
      strongestAnswer: strongAnswer?.answer
    };
  });

  return {
    title: module.title,
    type: module.type,
    matches: matchAndExplain,
    questions: explainQuestions
  };
};
