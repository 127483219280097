import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Body, Grid } from 'src/components/layout/Wrap';
import { useMappedAnswers } from 'src/hooks/useData';
import { RootState } from 'src/state/reducers';
import { answersSelector } from 'src/state/selectors';
import { Page } from 'src/styles/Layout';
const Data: FC<{ answers: Array<AnswerProps> }> = ({ answers }) => {
  const mappedModule1 = useMappedAnswers(answers, 'action-areas');
  const mappedModule2 = useMappedAnswers(
    answers,
    'health-promotion-strategies'
  );
  const mappedModule3 = useMappedAnswers(
    answers,
    'sustainable-development-goals'
  );

  console.log(mappedModule1.matches, mappedModule2, mappedModule3);

  return (
    <Body>
      <Page>
        <Grid>
          <h1>{mappedModule1.title}</h1>
          <div>
            <hr />
          </div>
          {mappedModule1.matches.map((g: any) => (
            <div key={g.title}>
              <h2>{g.title}</h2>
              <h4>Your Match:</h4>
              <p>{g.matchTitle}</p>
              <h2>{g.explainQuestion}</h2>
              <h4>Your Answer:</h4>
              <p>{g.answer}</p>
              <h4>Suggested Answer</h4>
              <p>{g.suggestedAnswer}</p>
              <hr />
            </div>
          ))}
          <h1>{mappedModule2.title}</h1>
          <div>
            <hr />
          </div>
          {mappedModule2.questions.map((g: any) => (
            <div key={g.title}>
              <h2>{g.title}</h2>
              <h3>{g.explainQuestion}</h3>
              <h4>Your Answer:</h4>
              <p>{g.answer}</p>
              <h4>Suggested Answer</h4>
              <p>{g.suggestedAnswer}</p>
              <hr />
            </div>
          ))}
          <h1>{mappedModule3.title}</h1>
          <div>
            <hr />
          </div>
          {mappedModule3.questions.map((g: any) => (
            <div key={g.title}>
              <h2>{g.title}</h2>
              <h3>{g.explainQuestion}</h3>
              <h4>Your Answer:</h4>
              <p>{g.answer}</p>
              <h4>Suggested Answer</h4>
              <p>{g.suggestedAnswer}</p>
              <hr />
            </div>
          ))}
        </Grid>
      </Page>
    </Body>
  );
};

export default connect((state: RootState) => ({
  answers: answersSelector(state)
}))(Data);
